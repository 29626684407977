import React from 'react'

class PianoLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.openPianoLogin = this.openPianoLogin.bind(this);
  }

  openPianoLogin(e) {
    e.preventDefault();
    window['tp'] = window['tp'] || [];
    window['tp'].push(['init', function() {
      window['tp'].pianoId.show({
        displayMode: 'modal',
        screen: 'login'
      });
    }]);
  }

  componentDidMount() {
    window['tp'] = window['tp'] || [];
    window['tp'].push(['init', function () {
      // Piano login event callback.
      window['tp'].push(['addHandler', 'loginSuccess', function (event) {
        window.location.reload(true);
      }]);
    }]);
  }

  render() {
    return (
      <button style={{'padding': 0, 'background': 'none', 'border': 'none', 'textDecoration': 'underline', 'cursor': 'pointer', 'color': '#3ba5d1', 'fontSize': '18px'}} onClick={this.openPianoLogin}>Log in</button>
    );
  }
}

export default PianoLogin
