import React from 'react'
import PropTypes from 'prop-types'
import './scss/quote.scss'

const Quote = ({ text, source = '', quoteStyle }) => (
  <blockquote className={`quote quote--${quoteStyle}`}>
    <div className={"quote__inner"}>
      {text}
    </div>
    {source && <div className={"quote__source"}></div>}
  </blockquote>
)

Quote.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  source: PropTypes.string,
}

export default Quote
