// Render Prop
import React from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Message from "./Message";
import PianoLogin from "./PianoLogin";

class MailChimpSignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitSuccess: false,
      submitError: false,
      formErrorMessage: ''
    };
  }

  render() {
    return <div>
      <Formik
        initialValues={{email: '', name_first: '', name_last: ''}}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Your email address is required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Provide a valid email address';
          }

          return errors;
        }}
        onSubmit={(values, {setSubmitting}) => {
          this.setState({formErrorMessage: ''})
          this.setState({submitError: false})
          this.setState({submitSuccess: false})
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
          const listFields = {
            FNAME: values.name_first,
            LNAME: values.name_last,
            // 'Digital Fractures Report' group in 'Static pages' category in 'Digital Fractures Report' audience
            'group[17802][1]': '1'
          }

          // A generic error message to show in case of an unspecified error
          const errorMsg = "Something went wrong. It's possible that your browser is blocking the email signup: try to temporarily disable any tracker/ad-blockers, and submit the form again. Contact support@nationalobserver.com if the error persists."
          addToMailchimp(values.email, listFields) // listFields are optional if you are only capturing the email address.
            .then(data => {
              // I recommend setting data to React state
              // but you can do whatever you want (including ignoring this `then()` altogether)
              // console.log(data)
              if (data.result === 'success' ||
                 (data.result === 'error' && data.msg.includes('is already subscribed to list'))) {
                this.setState({submitSuccess: true})
                this.props.mailChimpSuccess()
              } else if (data.result === 'error' && data.msg) {
                this.setState({submitError: true, formErrorMessage: data.msg})
              } else {
                this.setState({submitError: true, formErrorMessage: errorMsg})
              }
            })
            .catch(() => {
              // unnecessary because Mailchimp only ever
              // returns a 200 status code
              // see below for how to handle errors
              this.setState({submitError: true, formErrorMessage: errorMsg})
            })

        }}
      >
        {({isSubmitting}) => (

          <div>
            {this.state.submitError && <Message type={"error"} text={this.state.formErrorMessage}/>}

            {this.state.submitSuccess && <Message type={"success"} text={this.props.successMessage}/>}

            {(!this.state.submitSuccess &&
              this.props.formIntro) &&
                <div className={"form-intro"}>
                  {this.props.formIntro}
                </div>
            }
            {!this.state.submitSuccess &&
            <Form className={"form"}>
              <div className={"form-element form-element--text"}>
                <label htmlFor="name_first">First name</label>
                <Field type="text" name="name_first"/>
              </div>
              <div className={"form-element form-element--text"}>
                <label htmlFor="name_last">Last name</label>
                <Field type="text" name="name_last"/>
              </div>
              <div className={"form-element form-element--email"}>
                <label htmlFor="email">Email</label>
                <Field type="email" name="email"/>
                <ErrorMessage name="email" component="div" className={"form-error"}/>
              </div>
              {/*<div className={"form-element form-element--checkbox"}>*/}
              {/*<Field type="checkbox" name="consent"/> <label htmlFor="consent">I would like to subscribe to future communications from Canada's National Observer</label>*/}
              {/*</div>*/}
              <div className={"form-actions"}>
                <button type="submit" id="mailchimp-form-submit" className="button is-medium is-rounded is-primary is-size-5 has-text-weight-bold">
                  {this.props.buttonText}
                </button>
              </div>
              <div className={"form-footer"}>
                Already a subscriber? <PianoLogin />
              </div>
            </Form>
            }
          </div>
        )}
      </Formik>

    </div>
  }

}

export default MailChimpSignupForm;