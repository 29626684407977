import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import '../components/scss/landing_pages/landing_page_digital_fractures.scss'
import {Helmet} from 'react-helmet'
import useSiteMetadata from "../components/SiteMetadata";
import logo from "../img/logo_white.svg";
import MailChimpSignupForm from "../components/MailChimpSignupForm";
import queryString from 'query-string';
import Cookies from 'js-cookie';
import Quote from "../components/Quote";
import coverImg from "../img/cno-digital-fractures-report-thumbnail.jpg";

export class LandingPageDigitalFracturesTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pianoAccess: Cookies.get('_pc_piano_access')
    };
    this.reportUrl = 'https://www.nationalobserver.com/file/34831/download?token=nCfiQc31';
    this.mailChimpSuccess = this.mailChimpSuccess.bind(this);
  }

  mailChimpSuccess = function () {
    window.location = this.reportUrl;
  }

  render() {
    const PageContent = this.props.contentComponent || Content
    const today = new Date();
    const year = today.getFullYear();

    return (

      <div className="landing-page landing-page--digital-fractures">

        <Helmet>
          <script async={'true'} src="https://experience.tinypass.com/xbuilder/experience/load?aid=Z9bvuPACYA"/>
        </Helmet>

        <div className="content">
          <div className="content__inner">
            {this.props.helmet || ''}
            <header
              className="page-header"
            >
              <div className="page-header__inner">
                <div className="logo">
                  <a href="https://www.nationalobserver.com">
                    <img src={logo} alt="Canada's National Observer" style={{width: '180px', height: '70px'}}/>
                  </a>
                </div>
                <h1 className="page-title" dangerouslySetInnerHTML={{__html: this.props.title}}/>
                <a className="button is-medium is-rounded is-primary is-size-5 has-text-weight-bold" href="#download">
                  Download Report
                </a>
              </div>
            </header>
            <main className="page-main">
              <div className="page-main__content">
                <h2 className={"mt-0"} dangerouslySetInnerHTML={{__html: this.props.main_heading}}/>
                <section>
                  <PageContent className="content" content={this.props.content}/>
                </section>
                <footer className="page-main__footer">
                  <Quote text={this.props.quote} quoteStyle={"wide"}/>
                </footer>
              </div>
              <section className="page-main__sidebar" id="download">
                <div className="container sidebar__inner">
                  <h2 className={"mt-0"}>
                    Download Canada's National Observer's<br/><span>Digital Fractures Report</span>
                  </h2>

                  {((this.state.pianoAccess === '0' || typeof this.state.pianoAccess === 'undefined') && this.props.locationQuery.piano_status !== 'logged_in') &&
                  <MailChimpSignupForm
                    formIntro={"Please provide your name and email to download the report and limited discount for a subscription."}
                    buttonText={"Download Report"}
                    successMessage={`Thanks for signing up! Your download should start automatically. It if doesn't, <a href="${this.reportUrl}">click here</a> to download the report.`}
                    mailChimpSuccess={this.mailChimpSuccess}
                  />}

                  {(this.state.pianoAccess === '1' || this.props.locationQuery.piano_status === 'logged_in') &&
                  <div className={"whitepaper-download"}>
                    <div className={"whitepaper-download__cover"} style={{'margin-bottom': '20px'}}>
                      <img alt="Digital Fractures Report cover" src={coverImg} style={{'box-shadow': 'rgba(0, 0, 0, 0.5) 4px 4px 10px 0px'}} />
                    </div>
                    <div className={"whitepaper-download__button"} style={{'margin-top': '20px'}}>
                      <a id="download-report" className="button is-medium is-rounded is-primary is-size-5 has-text-weight-bold" href={this.reportUrl} style={{'max-width': '360px', 'width': '100%', 'height': '2.2em'}}>
                        Download Report
                      </a>
                    </div>
                  </div>}

                  {/*{this.props.locationQuery.utm_medium !== 'email' && <MailChimpSignupForm buttonText={"Download Report"}/>}*/}
                  {/*{this.props.locationQuery.utm_medium === 'email' && <WhitepaperDownload/>}*/}

                  {/*<div className={"paywall-showonnoaccess"}>Doesn't have paywall access</div>*/}
                  {/*<div className={"paywall-showonaccess"}>Has paywall access</div>*/}

                  {/*<iframe width="100%" height="500" src="http://eepurl.com/dPBBPn" frameBorder="0"></iframe>*/}
                </div>
              </section>
            </main>
            <footer className="page-footer">
              <div className={"page-footer__inner"}>
                <div className="logo">
                  <a href="https://www.nationalobserver.com">
                    <img src={logo} alt="Canada's National Observer" style={{width: '160px', height: '60px'}}/>
                  </a>
                </div>
                <div className={"copyright"}>
                  &copy; Observer Media Group {year}
                </div>
                <div className={"terms"}>
                  <ul>
                    <li><a href="https://www.nationalobserver.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a></li>
                    <li><a href="https://www.nationalobserver.com/terms" target="_blank" rel="noreferrer">Terms of Use</a></li>
                  </ul>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    )
  }
}

LandingPageDigitalFracturesTemplate.propTypes = {
  contentComponent: PropTypes.func,
  content: PropTypes.string,
  title: PropTypes.string.isRequired,
  main_heading: PropTypes.string,
  quote: PropTypes.string,
  helmet: PropTypes.object,
}

const LandingPageDigitalFractures = ({data, location}) => {
  const {markdownRemark: page} = data
  const {siteURL} = useSiteMetadata()

  return (
    <Layout location={location}>
      <LandingPageDigitalFracturesTemplate
        contentComponent={HTMLContent}
        title={page.frontmatter.title}
        content={page.html}
        quote={page.frontmatter.quote}
        main_heading={page.frontmatter.main_heading}
        helmet={
          <Helmet>
            <title>Digital Fractures Report by Canada's National Observer</title>
            <meta name="description"
                  content="Download Canada's National Observer's new report, Digital Fractures, which reveals the ominous role disinformation campaigns played during the 2019 federal election."/>
            <meta property="og:title" content="Digital Fractures Report"/>
            <meta name="og:description"
                  content="Download Canada's National Observer's new report, Digital Fractures, which reveals the ominous role disinformation campaigns played during the 2019 federal election."/>
            <meta property="og:image" content={`${siteURL}/img/Digital_Fractures_Social_Image_01.jpg`}/>
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="630"/>
            <meta name="twitter:title" content="Digital Fractures Report"/>
            <meta name="twitter:description"
                  content="Download Canada's National Observer's new report, Digital Fractures, which reveals the ominous role disinformation campaigns played during the 2019 federal election."/>
            <meta name="twitter:image" content={`${siteURL}/img/Digital_Fractures_Social_Image_01.jpg`}/>
          </Helmet>
        }
        locationQuery={queryString.parse(location.search)}
      />
    </Layout>
  )
}

LandingPageDigitalFractures.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default LandingPageDigitalFractures

export const LandingPageDigitalFracturesQuery = graphql`
  query LandingPageDigitalFractures($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        main_heading
        quote
      }
    }
  }
`
